<template>
    <div>
        <div v-if="cartItems">
            <div v-if="Object.keys(cartItems).length > 0">
                <div :class="{'reload': reload == true}">
                    <div v-show="error.show" class="alert alert-warning alert-dismissible fade show mb-2" role="alert">
                        <strong>Uwaga</strong> {{ error.message}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <table class="table">
                       <thead>
                           <tr>
                               <th width="15%">TWÓJ KOSZYK</th>
                               <th colspan="2"></th>
                               <th class="a-center d-none d-sm-table-cell">Cena</th>
                               <th class="a-center d-none d-sm-table-cell">Ilość</th>
                               <th class="a-center d-none d-sm-table-cell">Suma</th>
                           </tr>
                       </thead>
                       <tbody>
                            <tr v-for="(item) in cartItems">
                                <td class="image">
                                    <a :href="item.options.path" :title="item.name">
                                        <img :src="item.options.image" class="cart-image" alt="">
                                    </a>
                                </td>
                                <td class="">
                                    <a :href="item.options.path" :title="item.name">
                                        <h5 class="cart-product">
                                            {{ item.name }}
                                        </h5>
                                        <p v-if="item.options.addons.length > 0">
                                            <span class="info-span" v-for="(addon) in item.options.addons">Dodatek: {{ addon.name }} {{ addon.price }}zł<br></span>
                                        </p>
                                    </a>
                                    <p v-show="item.options.size != 999999">
                                        Rozmiar: {{ item.options.sizeLabel }} <br>
                                        <span class="d-block d-sm-none">Cena: {{ item.price }} zł</span>
                                    </p>    
                                </td> 
                                <td>
                                    <p class="click-item" @click="removeItem(item.rowId)" title="Usuń produkt"> 
                                        <svg fill="#00000099" width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24" ><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                    </p>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span>
                                        {{ item.price }} zł
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span  v-bind:class="blockUpdate(item.qty)" :disabled="item.qty == 1" @click="updateItem(item.rowId, item.qty-1)">
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm-6.5 10h13v1h-13v-1z"/></svg>
                                    </span> 
                                    <span class="qty">
                                        {{ item.qty }}                                         
                                    </span>
                                    <span class="click-item" @click="updateItem(item.rowId, item.qty+1)">
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 24 24"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
                                    </span> 
                                </td>    
                                <!-- Sub total -->
                                <td class="d-none d-sm-table-cell">
                                    {{ item.price * item.qty }} zł
                                </td>
                            </tr>
                            <tr class="table-dark ">
                                <td colspan="2">
                                    <span class="d-block d-sm-none">
                                        Razem: {{ cartTotal }} zł
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell"><span v-if="Object.keys(this.promocode).length > 0">Zniżka</span> </td>
                                <td class="d-none d-sm-table-cell"><span v-if="Object.keys(this.promocode).length > 0">{{ discountTotal }} zł</span></td>
                                <td class="d-none d-sm-table-cell">RAZEM</td>
                                <td class="d-none d-sm-table-cell">{{ cartTotal }} zł</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-100">
                    <span class="float-left">                        
                        <a href="/"><button class="btn btn-grey mt1">Kupuj dalej</button></a> 
                    </span>
                    <span class="float-right">                        
                        <a href="/checkout-login"><button class="btn btn-main mt1">Przejdź do kasy</button></a> 
                    </span>
                </div>
            </div>
            <div v-else>
                <h3>Twój koszyk jest pusty.</h3>
            </div>
        </div>
        <div v-else>
            <h3>Ładuję koszyk... </h3>
        </div>
    </div>
</template>
<script>
    export default {
        props: [],
        data: function() {
            return{     
                reload: false,
                cartItems: "",
                cartTotal: 0,
                discountTotal: 0,
                promocode: {},
                error: {
                    show: false,
                    message: "",
                }
            }
        },

        methods: {
            getCart() {                
                axios
                    .get('/get-cart')
                    .then(response => {
                        this.cartItems = response.data.cart;
                        this.cartTotal = response.data.total;
                        this.discountTotal = response.data.discountTotal;
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => this.reload = false)
            },
            getPromocodeLabel() {                
                axios
                    .get('/get-promocode-label')
                    .then(response => {
						this.promocode = response.data.promocode;
                     })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.reload = false)
            },
            removeItem(rowId) {  
                this.reload = true;
                axios
                    .post('/cart-remove', {
                        rowId: rowId
                    })
                    .then(response => {
                        this.getCart();       
                        this.promocode = {};           
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            updateItem(rowId, qty) {
                this.error.show = false;
                // block set qty to 0 and remove item
                if(qty > 0){                
                    this.reload = true;
                    axios
                        .post('/cart-update', {
                            rowId: rowId,
                            qty: qty
                        })
                        .then(response => {
                            if(!response.data.success){
                                this.error.show = true;
                                this.error.message = response.data.message;
                            }
                            
                            this.getCart();                        
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }  
            },
            blockUpdate(qty){
                if(qty > 1){
                    return "click-item";
                }

                return "disabled-item";
            }
        },
        created() {
            this.getCart();
            this.getPromocodeLabel();
        },
    }
</script>