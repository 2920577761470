<template>
	<li class="nav-item dropdown">
		<a class="nav-link dropdown-toggle" @click="openCart" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#000000"><path d="M6.665 9.068l-3.665-1.66v14l3.665 2.592 14.335-2.155v-14.845l-14.335 2.068zm-1.665 1.441l1 .453v10.118l-1-.707v-9.864zm14 9.615l-11 1.653v-10.881l11-1.587v10.815zm-2-15.833l-.001 1.749c0 .246-.18.455-.423.492-.303.045-.576-.19-.576-.495v-1.746c.001-.691-.231-1.304-.653-1.726-.368-.37-.847-.565-1.384-.565-1.547 0-2.96 1.558-2.963 3.268v1.681c0 .247-.181.457-.425.494-.302.046-.575-.189-.575-.494l.001-1.683c.004-2.261 1.866-4.266 3.962-4.266 1.717 0 3.039 1.387 3.037 3.291zm-9.999 2.209v-2.235c.004-2.26 1.866-4.265 3.962-4.265.492 0 .944.125 1.35.332-.423.17-.822.4-1.188.683l-.162-.015c-1.547 0-2.961 1.558-2.963 3.268v2.232c0 .248-.182.458-.427.494-.3.045-.572-.187-.572-.494z"/></svg>
			<span class="badge badge-pill badge-cart" v-show="cartQty > 0">
				{{ cartQty }}
			</span>
		</a>

	    <div>
			<div class="dropdown-menu dropdown-menu-right dropdown-cart" aria-labelledby="navbarDropdown">	
				<ul class="navbar-nav">
					<li class="nav-item" v-if="Object.keys(cartItems).length > 0">
						<div id="order">
					        <div class="row cart-item" v-for="(item, index) in cartItems" :key="index">
					            <div class="col-md-4">		                
				            		<a :href="item.options.path">
					                	<img :src="item.options.image" :alt="item.name" class="w-100">
				            		</a>
					            </div>
					            <div class="col-md-8">
				                    <p class="product-name">
				            			<a :href="item.options.path">
				                        	{{ item.name }}
				                        </a>
				                    </p>
					                <span v-show="item.options.size != 999999">Rozmiar: {{ item.options.sizeLabel }}<br></span> 
					                Ilość: {{ item.qty }} <br>
						            <!-- Sub total -->
					                Razem: {{ item.price * item.qty }} zł
					            </div>
					        </div> 
						</div>
						<hr id="cart-total">
						<p>Wartość zamówienia: <span class="float-right">{{ cartTotal }} zł</span></p>
					</li>
					<li v-else>
						<p class="empty-cart-text">Brak produktów w koszyku.</p>
					</li>
				</ul>
				<a href="/cart" v-if="Object.keys(cartItems).length > 0"><button class="btn btn-gold w-100 text-uppercase">Zobacz Koszyk</button></a>
			</div>
	    </div>
    </li>
</template>

<script>
	import EventListener from './../../../app.js';
    export default {
        props: [],
        data: function() {
            return{    
            	cartItems: 0, 
            	cartTotal: 0, 
            	cartQty: 0, 
            }
        },

        methods: {
            getCartItems(type = "start") {
                axios
                    .get('/get-cart')
                    .then(response => {
                        this.cartItems = response.data.cart;
	                    this.cartTotal = response.data.total;
	                    this.cartQty = response.data.qty;
		    			if(type == "add-item"){
			    			$('.dropdown-cart').show();                        
		    			}
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => { 
                    	setTimeout(function(){ $('.dropdown-cart').hide(); }, 2500);
                    });    
            },
            openCart(){
            	$('.dropdown-cart').toggle();                        
            },
        },
        created() {
        	this.getCartItems();
        },
        mounted () {
			EventListener.$on('add-to-cart', function () {
		    	this.getCartItems("add-item");
		    	}.bind(this), false
			)
		}
    }
</script>