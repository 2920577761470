/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

//event listener to add to cart
const EventListener = new Vue();
export default EventListener;


Vue.component('add-to-cart', require('./components/web/orders/AddToCart.vue').default);
Vue.component('cart-items', require('./components/web/orders/CartItems.vue').default);
Vue.component('cart', require('./components/web/orders/Cart.vue').default);
Vue.component('checkout', require('./components/web/orders/Checkout.vue').default);

const app = new Vue({
    el: '#app',
});

$('#filter').click(function () {
	$('#categories').slideToggle('slow', 'linear');
})

// css :has for Firefox
if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
  // Get the elements with the classes
  var elements = document.querySelectorAll('.navbar-menu .nav-item, .navbar-menu .dropdown-menu > li');

  // Modify the CSS properties of the elements
  for (var i = 0; i < elements.length; i++) {
    elements[i].style.display = 'block';
  }

  //filters
  var elementsFilters = document.querySelectorAll('.filter-item, .filter-tree');
    // Modify the CSS properties of the elementsFilters
  for (var i = 0; i < elementsFilters.length; i++) {
    elementsFilters[i].style.display = 'block';
  }
}